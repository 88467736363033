<template>
  <div class="main-content" id="main-content" style="width: 100%">
    <div class="center">
      <div class="move-left">
        <div class="move-title" style="padding-bottom: 20px;">
          <img src="@/assets/image/shop-logo02-black@2x.png" />
          <img src="/img/qicycle.png" />
<!--          <div class="move-title-vertical"></div>-->
<!--          <div class="movt-title-text">QiCYCLE</div>-->
        </div>
        <h5 class="title-app">{{ $t("downLoad.v1") }}</h5>
        <h6 class="title2">{{ $t("downLoad.v2") }}</h6>
        <div class="download">
          <div class="sps">
            <div class="imgs">
              <img src="@/assets/image/download/download01.png" />
            </div>
            <div style="margin-left: 15px" class="div11">
              <div class="div2 div21">
                <img class="img1" src="@/assets/image/download/key01.png" />
                <span class="span1" style="word-break: break-all"
                  >&nbsp;&nbsp;{{ $t("downLoad.v3") }}</span
                >
              </div>
              <div class="click-down1" name="click-down1" style="display: none">
                <a
                  href="https://apps.apple.com/cn/app/%E9%AA%91%E8%AE%B0-%E4%B8%93%E4%B8%9A%E5%8D%95%E8%BD%A6-%E8%87%AA%E8%A1%8C%E8%BD%A6%E9%AA%91%E8%A1%8C%E8%BF%90%E5%8A%A8%E8%BD%A8%E8%BF%B9%E8%AE%B0%E5%BD%95%E8%BD%AF%E4%BB%B6/id596677920"
                >
                  <img
                    style="margin-left: 20px"
                    src="@/assets/image/download/app_shop1.png"
                    alt=""
                  />
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {{ $t("downLoad.v5") }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </a>
                <div class="split"></div>
                <a
                  style="padding-top: 20px; padding-bottom: 20px"
                  href="http://image.iriding.cc/app/app-IRIDING-release.apk"
                >
                  <img
                    style="margin-left: 20px"
                    src="@/assets/image/download/location_app1.png"
                    alt=""
                  />
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{
                      $t("downLoad.v6")
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="sps sps2">
            <div class="imgs">
              <img src="@/assets/image/download/download02.png" />
            </div>
            <div style="margin-left: 15px" class="div12">
              <div class="div2 div22">
                <img class="img2" src="@/assets/image/download/key01.png" />
                <span class="span2" style="word-break: break-all"
                  >&nbsp;&nbsp;{{ $t("downLoad.v4") }}</span
                >
              </div>
              <div class="click-down2" name="click-down2" style="display: none">
                <a
                  style="padding-top: 20px; padding-bottom: 20px"
                  href="https://apps.apple.com/us/app/qicycle-global/id1573968683"
                >
                  <img
                    style="margin-left: 20px"
                    src="@/assets/image/download/app-store-fill.png"
                    alt=""
                  />
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{
                      $t("downLoad.v5")
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </a>
                <div class="split"></div>
                <a
                  style="padding-top: 20px; padding-bottom: 20px"
                  href="https://play.google.com/store/apps/details?id=com.global.iriding.mobile"
                >
                  <img
                    style="margin-left: 20px"
                    src="@/assets/image/download/google-play.png"
                    alt=""
                  />
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;Google
                    Play&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div class="d2">
        <img src="@/assets/image/shop-img/shop031@2x.png" />
      </div>
    </div>
    <to-top />
  </div>
</template>

<script>
import ToTop from "../../component/totop/toTop.vue";
export default {
  name: "download",
  data() {
    return {
      bool: false,
      doc: "",
    };
  },
  components: {
    ToTop,
  },
  mounted() {
    document.addEventListener("click", (e) => {
      var className1 = ["div11", "div21", "img1", "span1"];
      var className2 = ["div12", "div22", "img2", "span2"];
      if (className1.includes(e.target.className)) {
        this.download(1);
      } else if (className2.includes(e.target.className)) {
        this.download(2);
      } else {
        this.doc.style.display = "none";
      }
    });
  },
  methods: {
    download(index) {
      var cc = document.documentElement.clientWidth;
      var arr = document.querySelectorAll(
        "[name= 'click-down1'],[name= 'click-down2']"
      );
      arr.forEach((item) => {
        if (item.style.display == "block") {
          item.style.display = "none";
        }
      });
      var clname =
        cc <= 980
          ? index == 1
            ? ".click-down1"
            : ".click-down2"
          : index == 1
          ? ".ewm1"
          : ".ewm2";
      var downs = document.querySelector(clname);
      var ewstyle = downs.style.display;
      downs.style.display = ewstyle == "block" ? "none" : "block";
      this.doc = downs;
    },
  },
};
</script>

<style scoped lang="stylus">
body {
}

.ewm1 {
  width: 250px;
  height: 100px;
  position: fixed;
  margin-left: 206px;
  margin-top: -100px;
}

.ewm2 {
  width: 250px;
  height: 100px;
  position: fixed;
  margin-left: 206px;
  margin-top: -19px;
}

.center {
  padding-top: 10%;
  margin: 0 10%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;

  .move-left {
    width: 40%;
    height: 100%;

    .move-title {
      width: 326px;
      display: flex;

      >img:nth-child(1) {
        width: 34px;
        height: 28px;
        margin-top: 16px;
      }
      >img:nth-child(2) {
        width: auto;
        height: 28px;
        margin-top: 16px;
        margin-left 10px;
      }
    }

    .move-title-vertical {
      border: 1px solid rgba(51, 51, 51, 1);
      height: 25px;
      margin-left: 20px;
      margin-top: 15px;
      background-color: rgba(51, 51, 51, 1);
      color: rgba(51, 51, 51, 1);
    }

    .movt-title-text {
      margin-left: 20px;
      font-size: 40px;
    }

    .title-app {
      color: #000000;
      font-size: 20px;
      //letter-spacing: 6px;
      font-weight: 700;
      margin: 20px 0;
    }

    .title2 {
      color: #848484;
      //word-spacing: 5px;
      font-size: 12px;
      //font-family: inherit;
      font-weight: 500;
      line-height: 1.1;
    }

    .sps {
      height: 70px;
      display: flex;
      cursor: pointer;
      padding-top: 18%;
      font-size: 16px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .div2 {
        >img {
          padding-bottom: 4px;
        }
      }

      span {
      }

      .imgs {
        img {
          width: 50px;
          height: 50px;
        }
      }
    }

    .sps2 {
      padding-top: 0;
      margin-top: -4px;
    }
  }

  .d2 {
    width: 55%;
    height: 100%;

    img {
      width: 400px;
      height: 466px;
      margin-left: 20px;
      padding-top: 20px;
    }
  }
}

@media screen and (max-width: 980px) {
  .ewm1 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -19px;
  }

  .click-down1 {
    display: none;
    cursor: pointer;
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      display: flex;
      padding-top: 14px;
      padding-bottom: 14px;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      display: flex;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        margin: 20px 0;
        color: #000000;
        font-size: 20px;
        //letter-spacing: 4px;
        font-weight: 600;
      }

      .title2 {
        color: #848484;
        //letter-spacing: 3px;
        font-size: 12px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          margin-top: 6%;
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 { // 8989
      width: 55%;
      height: 100%;

      img {
        width: 204px;
        height: 240px;
        margin-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .ewm1 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -19px;
  }

  .click-down1 {
    display: none;
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding-top: 14px;
      padding-bottom: 14px;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      display: flex;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        margin: 20px 0;
        color: #000000;
        font-size: 20px;
        //letter-spacing: 4px;
        font-weight: 600;
      }

      .title2 {
        color: #848484;
        //letter-spacing: 3px;
        font-size: 12px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 {
      width: 55%;
      height: 100%;

      img {
        width: 204px;
        height: 240px;
        margin-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .ewm1 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -19px;
  }

  .click-down1 {
    display: none;
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding-top: 14px;
      padding-bottom: 14px;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      display: flex;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        margin: 20px 0;
        color: #000000;
        font-size: 20px;
        //letter-spacing: 4px;
        font-weight: 600;
      }

      .title2 {
        color: #848484;
        //letter-spacing: 3px;
        font-size: 12px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 { // 8989
      width: 55%;
      height: 100%;

      img {
        width: 204px;
        height: 240px;
        margin-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .ewm1 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -19px;
  }

  .click-down1 {
    display: none;
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding-top: 14px;
      padding-bottom: 14px;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      display: flex;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        margin: 20px 0;
        color: #000000;
        font-size: 20px;
        //letter-spacing: 4px;
        font-weight: 600;
      }

      .title2 {
        color: #848484;
        //letter-spacing: 3px;
        font-size: 12px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 { // 8989
      width: 55%;
      height: 100%;

      img {
        width: 204px;
        height: 240px;
        margin-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 411px) {
  .click-down1 {
    margin-top: -15px;
  }

  .click-down2 {
    margin-top: -15px;
  }
}

@media screen and (max-width: 375px) {
  .ewm1 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    width: 250px;
    height: 100px;
    position: fixed;
    margin-left: 206px;
    margin-top: -19px;
  }

  .click-down1 {
    display: none;
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding-top: 14px;
      padding-bottom: 14px;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    position: absolute;
    margin-top: -15px;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      display: flex;
      color: #333333;

      img {
        display: block;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        margin: 20px 0;
        color: #000000;
        font-size: 20px;
        //letter-spacing: 4px;
        font-weight: 600;
      }

      .title2 {
        color: #848484;
        //letter-spacing: 3px;
        font-size: 12px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 {
      width: 55%;
      height: 100%;

      img {
        width: 204px;
        height: 240px;
        margin-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 375px) and (min-height: 812px) {
  .click-down1 {
    margin-top: -15px;
  }

  .click-down2 {
    margin-top: -15px;
  }
}

@media screen and (max-width: 360px) {
  .click-down1 {
    margin-top: -15px;
  }

  .click-down2 {
    margin-top: -15px;
  }
}

@media screen and (max-width: 320px) {
  .click-down1 {
    margin-top: -15px;
  }

  .click-down2 {
    margin-top: -15px;
  }
}
</style>
